<template>
  <div class="ui feed">
    <div v-for="log in logs" :key="log.id" class="event">
      <div class="content">
        <div v-if="log.author === 'BU' && log.status_from === null" class="summary">
          {{ log.sales_rep_email }} submitted DRAFT
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>
        <div v-else-if="log.author === 'BU' && log.status_from === 'DR' && log.status_to === 'CR'" class="summary">
          {{ log.sales_rep_email }} sent
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>
          for CREATING.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>
        <div v-else-if="log.author === 'BU' && log.status_to === 'PR'" class="summary">
          {{ log.sales_rep_email }} sent
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>
          for PROOFING.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>
        <div v-else-if="log.author === 'BU' && log.status_from === 'CA' && log.status_to === 'CR'" class="summary">
          {{ log.sales_rep_email }} REJECTED
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>
        <div v-else-if="log.author === 'BU' && log.status_from === 'FI'" class="summary">
          {{ log.sales_rep_email }} REJECTED
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>

        <div v-else-if="log.author === 'CU' && (log.status_to === 'CO' || log.status_to === 'FI')" class="summary">
          Customer {{ log.customer_email }} APPROVED
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>
        <div v-else-if="log.author === 'CU'" class="summary">
          Customer {{ log.customer_email }} REJECTED
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>

        <div v-else-if="log.author === 'PH' && log.status_to === 'FI'" class="summary">
          Pixel's Hive FINISHED
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>
        <div v-else-if="log.author === 'PH' && log.status_to === 'PA'" class="summary">
          Pixel's Hive sent
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>
          for PROOF APPROVAL.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>
        <div v-else-if="log.author === 'PH' && log.status_to === 'DR'" class="summary">
          Pixel's Hive REJECTED
          <router-link :to="{ name: 'Order', params: { id: log.order } }">{{ log.order_reference_number }}-{{ log.order_sequence }}</router-link>.
          <div class="date">
            {{ DateTime.fromISO(log.date, { zone: 'utc' }).toRelative() }}
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="event">
      <div class="content">
        <div class="summary">
          pete@identitysource.com sent <a>50528-6</a> for CREATING.
          <div class="date">
            15 minutes ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          james@identitysource.com sent <a>56096-7</a> for PROOFING.
          <div class="date">
            2 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          Customer pam@tenethealth.com APPROVED <a>55312-1</a>.
          <div class="date">
            3 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          Pixel's Hive sent <a>55312-1</a> for CREATIVE APPROVAL.
          <div class="date">
            5 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          pete@identitysource.com sent <a>50528-6</a> for CREATING.
          <div class="date">
            7 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          james@identitysource.com sent <a>56096-7</a> for PROOFING.
          <div class="date">
            9 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          Customer henry@atos.net APPROVED <a>55312-1</a>.
          <div class="date">
            11 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          Pixel's Hive sent <a>55312-1</a> for PROOF APPROVAL.
          <div class="date">
            15 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          matt@identitysource.com sent <a>50528-6</a> for PROOFING.
          <div class="date">
            19 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          john@identitysource.com sent <a>56096-7</a> for PROOFING.
          <div class="date">
            23 hours ago
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          Customer tallen@gm.com APPROVED <a>55312-1</a>.
          <div class="date">
            Yesterday
          </div>
        </div>
      </div>
    </div>

    <div class="event">
      <div class="content">
        <div class="summary">
          Pixel’s Hive FINISHED <a>55312-1</a>.
          <div class="date">
            3 days ago
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  props: {
    logs: {
      type: Array,
      required: true
    }
  },
  
  data () {
    return {
      DateTime
    }
  }
}
</script>