<template>
  <div class="ui fluid container">
    <div class="ui grid">
      <div class="sixteen wide tablet ten wide computer column">
        <div class="ui raised segment">
          <h1>Orders</h1>
          <div class="ui equal width grid">
            <router-link
              :to="{ name: 'Orders', query: { has_issues: true } }"
              class="column"
            >
              <div class="ui segment-button raised segment">
                <div class="ui small statistic">
                  <div class="value">
                    <i v-if="loading.stats" class="mini loading spinner icon" />
                    <span v-else>
                      {{ stats.issues }}
                    </span>
                  </div>
                  <div class="label">
                    Issues
                  </div>
                </div>
              </div>
            </router-link>
            <router-link
              :to="{ name: 'Orders', query: { status: 'DR' } }"
              class="column"
            >
              <div class="ui segment-button raised segment">
                <div class="ui small statistic">
                  <div class="value">
                    <i v-if="loading.stats" class="mini loading spinner icon" />
                    <span v-else>
                      {{ stats.draft }}
                    </span>
                  </div>
                  <div class="label">
                    Drafts
                  </div>
                </div>
              </div>
            </router-link>
            <router-link
              :to="{
                name: 'Orders',
                query: { status: ['CR', 'PR', 'CO'] }
              }"
              class="column"
            >
              <div class="ui segment-button raised segment">
                <div class="ui small statistic">
                  <div class="value">
                    <i v-if="loading.stats" class="mini loading spinner icon" />
                    <span v-else>
                      {{ stats.production }}
                    </span>
                  </div>
                  <div class="label">
                    Production
                  </div>
                </div>
              </div>
            </router-link>
            <router-link
              :to="{
                name: 'Orders',
                query: { status: ['CA', 'PA'] }
              }"
              class="column"
            >
              <div class="ui segment-button raised segment">
                <div class="ui small statistic">
                  <div class="value">
                    <i v-if="loading.stats" class="mini loading spinner icon" />
                    <span v-else>
                      {{ stats.approval }}
                    </span>
                  </div>
                  <div class="label">
                    Approval
                  </div>
                </div>
              </div>
            </router-link>
            <router-link
              :to="{ name: 'Order', params: { id: 'new' } }"
              class="column"
            >
              <div class="ui segment-button raised segment">
                <div class="ui small blue statistic">
                  <div class="value">
                    <i class="plus icon" />
                  </div>
                  <div class="label">
                    New Order
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <orders-table :loading="loading.orders" />
          <router-link
            :to="{ name: 'Orders' }"
            style="margin-left: 0.2em; font-weight: bold;"
            >Manage all <i class="right arrow icon" />
          </router-link>
        </div>
      </div>
      <div class="sixteen wide tablet six wide computer column">
        <div class="ui raised segment">
          <h1>Activity</h1>
          <Logs :logs="logs" />
          <router-link
            :to="{ name: 'OrderLogs' }"
            style="margin-left: 0.2em; font-weight: bold;"
            >View all <i class="right arrow icon" />
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="profile && profile.role === 'A'" class="ui raised segment">
      <h1>Manage</h1>
      <div class="segment-button-container">
        <router-link
          :to="{ name: 'Invoicing' }"
          class="ui segment-button raised segment"
        >
          <div class="ui small statistic">
            <div class="label">
              INVOICING
            </div>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'BusinessUsers' }"
          class="ui segment-button raised segment"
        >
          <div class="ui small statistic">
            <div class="label">
              USERS
            </div>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'Manufacturers' }"
          class="ui segment-button raised segment"
        >
          <div class="ui small statistic">
            <div class="label" style="margin-left: -0.4em">
              MANUFACTURERS
            </div>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'Inks' }"
          class="ui segment-button raised segment"
        >
          <div class="ui small statistic">
            <div class="label" style="margin-left: -0.4em">
            STOCK INKS
            </div>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'Threads' }"
          class="ui segment-button raised segment"
        >
          <div class="ui small statistic">
            <div class="label" style="margin-left: -0.4em">
            STOCK THREADS
            </div>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'Vinyls' }"
          class="ui segment-button raised segment"
        >
          <div class="ui small statistic">
            <div class="label" style="margin-left: -0.4em">
            STOCK VINYLS
            </div>
          </div>
        </router-link>
        <!-- <div class="ui segment-button raised segment">
          <div class="ui small statistic">
            <div class="label">
              BUSINESS SETUP
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import OrdersTable from "@/components/OrdersTable";
import Logs from "@/components/Logs.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    OrdersTable,
    Logs
  },

  data() {
    return {
      logs: [],
      stats: {},
      loading: {
        logs: true,
        orders: true,
        stats: true
      }
    };
  },

  computed: {
    ...mapState(["orders", "profile"])
  },

  methods: {
    ...mapActions(["loadOrders"])
  },

  created() {
    this.loadOrders({ query: "?ordering=-created_at" }).then(() => {
      this.loading.orders = false;
    });

    const loadLogsAndSummary = () => {
      this.$http
        .get(`/api/v1/businesses/${this.profile.business}/orders-summary/`)
        .then(response => {
          this.stats = response.data;
          this.loading.stats = false;
        });
      this.$http
        .get(`/api/v1/businesses/${this.profile.business}/orderlogs/`)
        .then(response => {
          this.logs = response.data.results;
          this.loading.logs = false;
        });
    }

    if (this.profile) {
      loadLogsAndSummary();
    } else {
      this.$watch('profile', function () {
        loadLogsAndSummary();
      })
    }
  }
};
</script>

<style scoped>
.container {
  padding: 0 3em 0 3em;
}

.segment {
  height: 100%;
}

.segment-button-container {
  display: flex;
}

.segment-button {
  align-items: center;
  display: flex;
  width: 10em;
  height: 10em;
  margin-top: 0;
  margin-right: 3em;
}

.segment-button > div {
  margin: 0 auto;
}
</style>
